.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

.logo {
  height: 100px;
  width: 60%;
}

.site-layout .site-layout-background {
  background: #fff;
}
